import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/TemplateTexts/';

class HostMessageService {
  postPreview(templateText: {
    id: string;
    name: string;
    textDe: string;
    subjectDe: string;
    textEn: string;
    subjectEn: string;
    textType: string;
    variableType: string;
  }) {
    return ApiService.post(`${API_URL}Preview`, templateText);
  }

  update(templateText: {
    id: string;
    name: string;
    textDe: string;
    subjectDe: string;
    textEn: string;
    subjectEn: string;
    textType: string;
    variableType: string;
  }) {
    return ApiService.post(`${API_URL}Edit`, templateText);
  }

  getEdit(templateTextId: string) {
    return ApiService.get(`${API_URL}Edit?templateTextId=${templateTextId}`);
  }

  createNew(templateText: { templateTextType: string }) {
    return ApiService.post(`${API_URL}Create`, templateText);
  }

  delete(templateTextId: string) {
    return ApiService.delete(`${API_URL}Delete?Id=${templateTextId}`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new HostMessageService();
